import type {
  ProductIdReviewReqType, RestaurantReviewByIdType,
  RestaurantIdReviewReqType, ProductReviewByIdType,
	 ReviewsProductType,
  ReviewsRestaurantType, AddReviewsProductReqType, AddReviewsRestaurantReqType,
  ReviewProductListResponceType,
  ReviewsRestaurantListResponceType,
  ReactToReviewRequestType,
  LikeAndDislikeCounterInReview
} from '~/composables/reviews/types/ReviewsType';
import type { ServerResponseCommonType } from '~/types/commons';

type useReviewsType = {
	addRestaurantReviews:(options:AddReviewsRestaurantReqType)=>Promise<boolean>;
	addProductReviews:(options:AddReviewsProductReqType)=>Promise<boolean>;
	getRestaurantReviews:() => Promise<ReviewsRestaurantType[]>;
	getProductReviews:() => Promise<ReviewsProductType[]>;
	getRestaurantReviewsById:(options:RestaurantIdReviewReqType)=> Promise<RestaurantReviewByIdType[]>;
	getProductReviewsById:(options:ProductIdReviewReqType)=> Promise<ProductReviewByIdType[]>;
  reactToReview: (body:ReactToReviewRequestType) => Promise<LikeAndDislikeCounterInReview | null>
}

export function useReviews ():useReviewsType {
  const { $api } = useNuxtApp();

  async function getRestaurantReviews () {
    const res = await $api<ServerResponseCommonType<ReviewsRestaurantListResponceType>>('user/feedback/getRestaurantsList')
	    .catch((err) => { throw err; });

    return res && res.data ? res.data.list : [];
  }

  async function getProductReviews () {
    const res = await $api<ServerResponseCommonType<ReviewProductListResponceType>>('user/feedback/getProductsList')
      .catch((err) => { throw err; });

    return res && res.data ? res.data.list : [];
  }

  async function addRestaurantReviews (options:AddReviewsRestaurantReqType) {
    const res = await $api<ServerResponseCommonType>('user/feedback/addRestaurant', {
      method: 'POST',
	    body: { ...options }
    }).catch((err) => { throw err; });

    return !!(res && res.message === 'Success');
  }

  async function addProductReviews (options:AddReviewsProductReqType) {
    const res = await $api<ServerResponseCommonType>('user/feedback/addProduct', {
      method: 'POST',
	    body: { ...options }
    }).catch((err) => { throw err; });

    return !!(res && res.message === 'Success');
  }

  async function getRestaurantReviewsById (options:RestaurantIdReviewReqType):Promise<RestaurantReviewByIdType[]> {
    const res = await $api<ServerResponseCommonType<RestaurantReviewByIdType[]>>('restaurant/getFeedbacks', {
      query: {
        per_page: 15,
        current_page: 1,
        ...options
      }
    });

    return res && res.data.length ? res.data : [];
  }

  async function getProductReviewsById (options:ProductIdReviewReqType):Promise<ProductReviewByIdType[]> {
    const res = await $api<ServerResponseCommonType<ProductReviewByIdType[]>>('product/getFeedbacks', {
      query: {
        per_page: 15,
        current_page: 1,
        ...options
      }
    });

    return res && res.data.length ? res.data : [];
  }

  async function reactToReview (body:ReactToReviewRequestType): Promise<LikeAndDislikeCounterInReview | null> {
    const res = await $api<ServerResponseCommonType<LikeAndDislikeCounterInReview>>('user/feedback/addProductFeedbackLike', {
      method: 'POST',
      body: { ...body }
    });
    if (res && res.data) {
      return res.data;
    }
    return null;
  }

  return {
	  getProductReviewsById,
	  getRestaurantReviewsById,
	  getProductReviews,
	  getRestaurantReviews,
	  addRestaurantReviews,
    reactToReview,
	  addProductReviews
  };
}
