<template>
  <div class="p-4 lg:p-5 pb-2 lg:pb-3 rounded-4 lg:rounded-5 bg-white">
    <p class="text-tekstovyy text-4.25 font-700">{{ review.name }}</p>

    <Rating
      :rating="review.score"
      class="mt-2.5"
    />

    <!--    <div-->
    <!--      v-if="imgs?.length ?? 0 > 0"-->
    <!--      class="flex gap-2 mt-3"-->
    <!--    >-->
    <!--      <img-->
    <!--        v-for="img in imgs"-->
    <!--        :key="`review-${img}`"-->
    <!--        :src="img"-->
    <!--        class="size-18 rounded-3 object-cover object-center"-->
    <!--        alt="img"-->
    <!--      >-->
    <!--    </div>-->

    <p class="text-tekstovyy-seryy text-3.75 leading-1.2 mt-3">{{ review.message }}</p>

    <!--    <div-->
    <!--      v-if=" false"-->
    <!--      class="flex flex-col gap-1 pt-3.5 border-t border-border/50 mt-3.5"-->
    <!--    >-->
    <!--      <Button-->
    <!--        v-if="!isShow"-->
    <!--        suffix-icon="i-custom:arrow-down"-->
    <!--        size="xs"-->
    <!--        color="green-light"-->
    <!--        @click="isShow = true"-->
    <!--      >-->
    <!--        Отзывы к блюдам {{ dishReviews?.length }}-->
    <!--      </Button>-->
    <!--      <template v-if="false">-->
    <!--        <div-->
    <!--          v-for="(item, idx) in dishReviews"-->
    <!--          :key="`review-${idx}`"-->
    <!--          class="flex gap-2 justify-between items-center py-4 px-4.5 rounded-2.5 bg-#e7f2db/50"-->
    <!--        >-->
    <!--          <div>-->
    <!--            <p class="text-tekstovyy text-4 font-700">{{ item.name }}</p>-->
    <!--            <Rating-->
    <!--              :rating="item.rating"-->
    <!--              class="mt-2"-->
    <!--            />-->
    <!--            <p class="text-#718997 text-3.5 leading-1.2 font-500 mt-3">-->
    <!--              Комментарий<span class="font-700">:</span>-->
    <!--              <span class="font-400">{{ item.comment }}</span>-->
    <!--            </p>-->
    <!--          </div>-->
    <!--          <span class="i-custom:arrow-right text-4.5 text-firmennyy"></span>-->
    <!--        </div>-->
    <!--      </template>-->
    <!--    </div>-->
    <div class="mt-3 py-2 flex items-center border-t border-border/50 gap-3 text-3.25 leading-1.2">
      <p class="mr-auto text-seryy">{{ format(review.created_at, 'D MMMM, hh:mm') }}</p>
      <div
        class="flex items-center gap-1 text-tekstovyy font-500"
        @click="handleLike"
      >
        <span class="text-5.25 text-firmennyy i-custom:like"></span>
        {{ count_likes }}
      </div>
      <div
        class="flex items-center gap-1 text-tekstovyy font-500"
        @click="handleDislike"
      >
        <span class="text-5.25 text-firmennyy i-custom:unlike"></span>
        {{ count_dislikes }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { format } from '@formkit/tempo';
import type { ProductReviewByIdType, RestaurantReviewByIdType } from '~/composables/reviews/types/ReviewsType';
import { useReviews } from '~/composables/reviews/useReviews';
const { reactToReview } = useReviews();
const isShow = ref(false);

const props = defineProps<{
  review:ProductReviewByIdType | RestaurantReviewByIdType
  // likes: number,
  // dislikes: number,
  // dishReviews?: { name: string, rating: number, comment: string }[]
}>();
defineEmits(['update-review']);
const count_likes = ref(props.review.count_likes);
const count_dislikes = ref(props.review.count_dislikes);
const is_likes_changed = ref(props.review.user_liked);
const is_dislikes_changed = ref(props.review.user_disliked);

const handleLike = () => {
  if (is_likes_changed.value) { return; } // Если уже был лайк, ничего не делаем

  if (is_dislikes_changed.value) {
    // Если до этого был дизлайк, убираем его
    count_dislikes.value--;
    is_dislikes_changed.value = false;
  }

  // Добавляем лайк
  count_likes.value++;
  is_likes_changed.value = true;
  reactToReview({ feedback_id: props.review.id, is_like: 1 });
};

const handleDislike = () => {
  if (is_dislikes_changed.value) { return; } // Если уже был дизлайк, ничего не делаем

  if (is_likes_changed.value) {
    // Если до этого был лайк, убираем его
    count_likes.value--;
    is_likes_changed.value = false;
  }

  // Добавляем дизлайк
  count_dislikes.value++;
  is_dislikes_changed.value = true;
  reactToReview({ feedback_id: props.review.id, is_like: 0 });
};

</script>
